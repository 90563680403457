<template>
    <section class="sign-cards">
        <v-container>
            <div class="sign-cards__container">
                <router-link v-for="(item, index) of signs" :key="index"
                             :to="{name:'Sign', params:{id:item.id}}" class="sign-cards__card"
                             :style="{'animation-delay':(1250+index*250)+'ms'}">
                    <div class="sign-cards__card-inner">
                        <div class="sign-cards__content">
                            <img :src="item.img" class="d-md-none" alt="">
                            <img :src="item.imgMD" class="d-none d-md-block" alt="">
                            <div class="sign-cards__label">{{ item.label }}</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </v-container>
    </section>
</template>

<script>
    import config from "../../config.js";

    export default {
        computed: {
            signs() {
                return this.$store.state.signs;
            }
        }
    }
</script>

<style lang="scss">
    .sign-cards {
        @include up($md) {
            padding-bottom: 20px;
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto;
            width: 144px+8px+144px;

            @include up($sm) {
                width: 144px+8px+144px+8px+144px;
            }

            @include up($md) {
                width: 240px+12px+240px+12px+240px;
            }

            @include up($lg) {
                width: 240px+12px+240px+12px+240px+12px+240px;
            }

            @include up($xl) {
                width: 240px+12px+240px+12px+240px+12px+240px+12px+240px+12px+240px;
            }
        }

        &__card {
            animation: card-move 1s;
            opacity: 0;
            animation-fill-mode: forwards;
            display: flex;
            width: 144px;
            height: 180px;
            margin-bottom: 8px;

            @include up($md) {
                width: 240px;
                height: 300px;
                margin-bottom: 12px;
            }

            &-inner {
                display: flex;
                width: 100%;
                padding: 1px;
                position: relative;
                background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%); //yellow;
                border-radius: 16px;
                box-shadow: 0 12px 16px rgba(0, 0, 0, 0.1);
                @include transition();

                &:hover {
                    background: linear-gradient(324.99deg, rgba(255, 255, 255, 0.1) -27.32%, rgba(255, 255, 255, 0.03) 103.72%);
                    box-shadow: 0 12px 16px rgba(0, 0, 0, 0);
                    transform: translateY(1px);

                    @include up($md) {
                        transform: translateY(2px);
                    }
                }
            }
        }

        @keyframes card-move {
            from {
                opacity: 0;
                transform: translateY(30px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 16px;
            background: conic-gradient(from 155.62deg at 50% 50%, $card-primary 0deg, $card-secondary 184.19deg, $card-primary 360deg, $card-primary 360deg);
            width: 100%;
            padding: 12px;
            box-sizing: border-box;

            @include up($md) {
                padding: 24px 24px;
            }

            img {
                display: block;
                width: 120px;
                height: 120px;
                margin: 0 auto;

                @include up($md) {
                    width: 176px;
                    height: 176px;
                }
            }
        }

        &__label {
            font-size: 14px;
            font-weight: 400;
            line-height: 135%;
            color: $white-color;

            @include up($md) {
                font-family: $header-font-family;
                font-size: 24px;
                line-height: 35px;
            }
        }
    }
</style>