<template>
    <div class="collection-slider" v-if="slides && slides.length">
        <v-container class="text-center">
            <div data-aos="fade-up" data-aos-delay="500">
                <h2>Zodiac collection</h2>
                <h4 class="mt-4 mt-md-0">zodiac signs</h4>
            </div>
            <p class="mt-4 mt-md-13 mx-auto" data-aos="fade-up" data-aos-delay="750">
                Персональная подборка для образа по твоему знаку зодиака из новой коллекции Zarina
            </p>

            <div class="mt-11 mt-md-0 pt-md-16 collection-slider__gallery">

                <swiper class="mt-md-3" ref="swiper" :options="swiperOption" @slideChange="onSlideChange">
                    <swiper-slide class="collection-slider__slide" v-for="(item, index) in slides" :key="index">
                        <a :href="item.link" target="_blank" class="collection-slider__photo"
                           :style="'background-image:url('+item.img+')'">
                            <!--<v-img :src="item.img"/>-->
                            <div class="d-md-none collection-slider__product">
                                <span class="collection-slider__product-name">{{ item.name }}</span>
                                <div class="collection-slider__product-price">
                                    <!--<img class="product-price__icon" src="~@/assets/icons/shopping-bag.svg" alt="shopping-bag">-->
                                    <svgicon class="collection-slider__product-price-icon" name="shopping-bag"/>
                                    <span class="collection-slider__product-price-value">{{item.price}} руб.</span>
                                </div>
                            </div>
                        </a>
                        <div class="d-none d-md-block mt-3 collection-slider__product--md">
                            <span class="collection-slider__product-name">{{ item.name }}</span>
                            <div class="collection-slider__product-price">
                                <!--<img class="product-price__icon" src="~@/assets/icons/shopping-bag.svg" alt="shopping-bag">-->
                                <svgicon class="collection-slider__product-price-icon" name="shopping-bag"/>
                                <span class="collection-slider__product-price-value">{{item.price}} руб.</span>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>

                <slider-btns class="d-sm-none mt-4 mt-md-10 collection-slider__btns"
                             mirror
                             :total="slides.length" :currentSlide="currentSlide"
                             @slideNext="slideNext" @slidePrev="slidePrev">
                    <!--<template slot="pagination">
                        <btn-link class="d-none d-sm-inline-block d-md-none" href="https://zarina.ru/catalog/new/" target="_blank"/>
                        <h3 class="d-none d-md-block collection-slider__collection">collection</h3>
                    </template>-->
                </slider-btns>

                <btn-link class="mt-12 mt-md-16 order-2 mx-auto" href="https://zarina.ru/catalog/new/" target="_blank"/>
            </div>
        </v-container>
    </div>
</template>

<script>
    import '../../plugins/vue-awsome-swiper';
    import SliderBtns from './slider-btns';
    import BtnLink from '../../components/btn-link';

    export default {
        name: "collection-slider",
        props: {
            id: {
                type: String,
                default: null
            }
        },
        components: {
            BtnLink,
            SliderBtns
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 16,
                    loop: false,
                    //freeMode: true,
                    //initialSlide: 1,
                    //centeredSlides: true,
                    breakpoints: {
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 16
                        },
                        960: {
                            slidesPerView: 3,
                            spaceBetween: 32
                        },
                        /*1280: {
                            slidesPerView: 4,
                            spaceBetween: 48
                        },*/
                    }
                },
                currentSlide: 1,
            }
        },
        computed: {
            sign() {
                return this.$store.state.signs.find(el => el.id === this.id);
            },
            slides() {
                return this.sign?.slides;
            },
            total() {
                return this.slides.length;
            },
        },
        methods: {
            onSlideChange() {
                this.currentSlide = (this.$refs.swiper?.$swiper?.activeIndex || 0) + 1;
            },
            slideNext() {
                this.$refs.swiper?.$swiper?.slideNext(500);
            },
            slidePrev() {
                this.$refs.swiper?.$swiper?.slidePrev(500);
            },
        },
        mounted() {
            setTimeout(() => {
                this.$store.state.swipers['collection-slider'] = this.$refs.swiper?.$swiper;
            }, 300);
        }
    }
</script>

<style lang="scss">
    .collection-slider {
        padding-top: 40px;
        //padding-bottom: 35px;

        @include up($sm) {
            padding-top: 80px;
        }

        h4 {
            opacity: 0.7;
        }

        p {
            max-width: 600px;
        }

        &__gallery {
            max-width: 320px;
            margin: 0 auto;

            @include up($sm) {
                max-width: 640px;
            }
            @include up($md) {
                max-width: 960px;
            }
            /*@include up($lg) {
                max-width: 1400px;
            }*/
        }

        &__photo {
            display: block;
            position: relative;
            width: 100%;
            height: 444px;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__product {
            position: absolute;
            z-index: 5;
            height: 35px;
            font-size: 14px;
            line-height: 35px;
            padding: 0 16px 0 16px;
            left: 12px;
            bottom: 12px;
            background-color: $black-color;
            border-radius: 17px;
            display: flex;

            &--md {
                font-size: 15px;
                line-height: 24px;
                text-align: left;
            }

            &-name {
                display: inline-block;
                color: $white-color;

                @include up($md) {
                    position: static;
                    display: block;
                    height: auto;
                }
            }

            &-price {
                display: inline-block;
                color: $white-color;

                svg {
                    //width: 10.39px;
                    height: 13px;
                    margin: 0 8px;
                    fill: $white-color;
                    position: relative;
                    top: -2px;

                    @include up($md) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

</style>