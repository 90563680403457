<template>
    <div class="slider-btns">
        <div class="d-flex flex-grow-1 justify-space-between align-center slider-btns__wrap">
            <button class=" slider-btns__btn1" @click="$emit('slidePrev')">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 8L1 8M1 8C3.40741 8.53333 8.22222 10.48 8.22222 14M1 8C3.40741 7.46667 8.22222 5.52 8.22222 2"
                          stroke="white"/>
                </svg>
            </button>
            <div class="d-md-none txt slider-btns__pagination">
                <slot name="pagination">
                    <span>{{currentSlide}}</span>
                    <span> / </span>
                    <span>{{total}}</span>
                </slot>
            </div>
            <button class="ml-md-4 slider-btns__btn2" @click="$emit('slideNext')">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 8L1 8M1 8C3.40741 8.53333 8.22222 10.48 8.22222 14M1 8C3.40741 7.46667 8.22222 5.52 8.22222 2"
                          stroke="white"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'slider-btns',
        props: {
            total: {},
            currentSlide: {},
        }
    }
</script>

<style lang="scss">
    .slider-btns {

        button {
            width: 52px;
            height: 52px;
            padding: 0;
            border-radius: 26px;
            background: $text-color;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__btn2 {
            svg {
                transform: rotateY(180deg);
            }
        }

        &__pagination {
            color: $white-color;
        }
    }
</style>
