<template>
    <div class="sign" id="sign">
        <sign-page :id="id"/>
        <collection-slider :id="id"/>
        <socials/>
    </div>
</template>

<script>
    import SignPage from "./sign/sign-page";

    import CollectionSlider from "./sign/collection-slider";
    import Socials from "./home/socials";

    export default {
        components: {Socials, CollectionSlider, SignPage},
        props: {
            id: {
                default: null,
                type: String
            }
        },
        created() {
            if ( !this.id ) {
                this.$router.push({name:'Home'});
            }
        }
    }
</script>

<style lang="scss">
    #sign {
        position: relative;

        @include up($sm) {
            background-image: url("~@/assets/ellipse2.svg");
            background-position: right 380px;
            background-size: 100%;
        }

        @include up($md) {
            background-position: right 340px;
        }

        @include up($lg) {
            background-position: right 340px;
        }

        @include up($xl) {
            background-position: right 340px;
        }

        /*&:after {
            @include up($xl) {
                position: absolute;
                content: "";
                background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                opacity: 0.08;
                filter: blur(100px);
                width: 100%;
                height: 1994px;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }*/
    }

</style>