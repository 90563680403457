<template>
    <section class="sign-page" v-if="sign">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <router-link :to="{name:'Home'}" class="parag sign-page__link">на главную</router-link>

                    <v-row class="mt-5 d-md-flex justify-space-between align-start sign-page__inner">
                        <v-col cols="12" sm="6">
                            <div class="sign-page__left">
                                <div data-aos="fade-left" data-aos-delay="250">
                                    <h1>{{sign.label}}</h1>
                                    <p class="mt-3 sign-p sign-page__preview">
                                        {{sign.preview}}
                                    </p>
                                </div>
                                <div class="d-flex justify-center justify-sm-start">
                                    <!--  data-aos="fade-up-left" data-aos-delay="750" -->
                                    <div class="mt-11 sign-page__image">
                                        <div class="sign-page__image-circle">
                                            <img :src="sign.imgMD" :alt="sign.label">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="5">
                            <ul v-if="texts && texts.length" class="mt-8 mt-sm-3 sign-page__list">
                                <li v-for="(text, index) of texts"
                                    data-aos="fade-up" :data-aos-delay="(isSM?1750:0)+(index*250)"
                                    data-aos-anchor=".sign-page__list">
                                    <p v-html="text"></p>
                                </li>
                            </ul>

                            <to-share :currentSign="sign.label" class="d-none d-md-block mt-10 mt-sm-16"/>
                        </v-col>
                    </v-row>

                    <to-share :currentSign="sign.label" class="d-block d-md-none mx-auto mt-10"/>

                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    import ToShare from "../../components/to-share";
    import headful from "headful";

    export default {
        components: {ToShare},
        props: {
            id: {
                default: null,
                type: String
            }
        },
        computed: {
            sign() {
                return this.$store.state.signs.find(el => el.id === this.id);
            },
            texts() {
                return this.sign.text?.split("\n").filter(el => !!el);
            },
            isSM() {
                return this.$store.getters.isWindowSM;
            }
        },
        mounted() {
            if (this.sign) {
                headful({
                    url: window.location.href,
                    title: this.sign.label + " – гороскоп от Zarina",
                    description: "Посмотрите мой гороскоп для знака " + this.sign.label + " от Zarina",
                });
            }
        }
    }
</script>

<style lang="scss">
    .sign-page {
        padding-top: 80px;
        padding-bottom: 16px;

        @include up($lg) {
            padding-top: 96px;
        }

        &__link {
            color: $text-color;
            opacity: 0.5;
            position: relative;
            padding-left: 23px;

            &:before {
                position: absolute;
                content: "";
                background-image: url("~@/assets/next.png");
                width: 16px;
                height: 16px;
                top: 6px;
                left: 0;
            }
        }

        &__preview {
            font-family: $header-font-family;
            font-size: 16px;
            line-height: 136%;
            @include up($lg) {
                font-size: 24px;
                line-height: 146%;
            }
        }

        &__image {
            display: flex;
            width: 296px;
            height: 296px;
            padding: 1px;
            box-sizing: border-box;
            position: relative;
            background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%); //yellow;
            border-radius: 50%;
            box-shadow: 0 12px 16px rgba(0, 0, 0, 0.1);

            //transform: rotate(90deg);
            animation: sign-move 1.5s;
            animation-delay: 0.75s;
            transform-origin: 300px 300px;
            opacity: 0;
            animation-fill-mode: forwards;
            //animation-direction: alternate;

            @include up($md) {
                width: 420px;
                height: 420px;
            }

            @include up($xl) {
                width: 572px;
                height: 572px;
            }

            &-circle {
                width: 294px;
                height: 294px;
                padding: 62px;
                box-sizing: border-box;
                background: conic-gradient(from 155.62deg at 50% 50%, #102235 0deg, #18293A 184.19deg, #102235 360deg, #102235 360deg);
                box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.1);
                border-radius: 50%;

                @include up($md) {
                    padding: 76px;
                    width: 418px;
                    height: 418px;
                }

                @include up($xl) {
                    padding: 115px;
                    width: 570px;
                    height: 570px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__list {

            p {
                @include up($md) {
                    font-size: 20px;
                    line-height: 135%;
                }
            }

            li {
                &:nth-child(odd) {
                    p {
                        position: relative;
                        font-weight: 700;
                        margin-top: 32px !important;
                        padding-left: 24px;
                        padding-bottom: 16px;

                        @include up($md) {
                            padding-left: 0;
                        }

                        &::before {
                            position: absolute;
                            content: "";
                            background-image: url("~@/assets/star-sm.svg");
                            width: 12px;
                            height: 12px;
                            left: 0;
                            top: 2px;
                            @include up($md) {
                                left: -52px;
                                top: 1px;
                                background-image: url("~@/assets/star-lg.svg");
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
                &:first-child {
                    p {
                        margin-top: 0 !important;
                    }
                }
            }
        }

        @keyframes sign-move {
            from {
                opacity: 0;
                transform: rotate(-30deg) translateX(500px) translateY(500px);
            }
            to {
                opacity: 1;
                transform: rotate(0deg) translateX(0px) translateY(0px);
            }
        }
    }
</style>