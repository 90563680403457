<template>
    <div class="to-share">
        <div class="d-block d-md-flex justify-space-between align-center to-share__inner">
            <div class="parag text-center">поделиться:</div>

            <ul class="mt-6 mt-md-0 mx-auto mx-md-0 d-flex justify-space-between">
                <li class="socials__item">
                    <ShareNetwork id="share-vk"
                                  network="vk"
                                  :url="url"
                                  :title="title"
                                  :description="description"
                                  :media="media"
                                  hashtags="zarina"
                    >
					<span class="socials__link">
						<svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
							<path d="M13.2767 5.68527C13.018 5.3586 13.092 5.21327 13.2767 4.92127C13.28 4.91794 15.4153 1.96727 15.6353 0.966601L15.6367 0.965934C15.746 0.601267 15.6367 0.333267 15.108 0.333267H13.3587C12.9133 0.333267 12.708 0.563267 12.598 0.820601C12.598 0.820601 11.7073 2.9526 10.4473 4.3346C10.0407 4.73393 9.85267 4.86194 9.63067 4.86194C9.52133 4.86194 9.35133 4.73394 9.35133 4.36927V0.965934C9.35133 0.528601 9.22667 0.333267 8.858 0.333267H6.10733C5.828 0.333267 5.662 0.537267 5.662 0.727267C5.662 1.14193 6.292 1.23727 6.35733 2.40393V4.93527C6.35733 5.48994 6.25667 5.59194 6.03333 5.59194C5.43867 5.59194 3.99533 3.45127 3.14 1.00127C2.96733 0.525934 2.79867 0.333934 2.35 0.333934H0.6C0.100667 0.333934 0 0.563934 0 0.821267C0 1.27593 0.594666 3.5366 2.76533 6.52327C4.212 8.56194 6.24933 9.6666 8.10267 9.6666C9.21667 9.6666 9.35267 9.42127 9.35267 8.99927C9.35267 7.05127 9.252 6.86727 9.81 6.86727C10.0687 6.86727 10.514 6.99527 11.554 7.9786C12.7427 9.1446 12.938 9.6666 13.6033 9.6666H15.3527C15.8513 9.6666 16.104 9.42127 15.9587 8.93727C15.626 7.91927 13.378 5.82527 13.2767 5.68527Z"
                                  fill="white"/>
						</svg>
					</span>
                    </ShareNetwork>
                </li>
                <li class="socials__item">
                    <ShareNetwork id="share-facebook"
                                  network="facebook"
                                  :url="url"
                                  :title="title"
                                  :description="description"
                                  :media="media"
                                  hashtags="zarina"
                    >
                        <span class="socials__link">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2733:50)">
                                    <path d="M10.6645 2.65667H12.1252V0.112667C11.8732 0.078 11.0065 0 9.99718 0C7.89118 0 6.44851 1.32467 6.44851 3.75933V6H4.12451V8.844H6.44851V16H9.29784V8.84467H11.5278L11.8818 6.00067H9.29718V4.04133C9.29785 3.21933 9.51918 2.65667 10.6645 2.65667Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2733:50">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </ShareNetwork>
                </li>
                <li class="socials__item">
                    <ShareNetwork id="share-telegram"
                                  network="telegram"
                                  :url="url"
                                  :title="title"
                                  :description="description"
                                  :media="media"
                                  hashtags="zarina"
                    >
                        <span class="socials__link">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2733:53)">
                                    <path d="M6.27812 10.1205L6.01345 13.8432C6.39212 13.8432 6.55612 13.6805 6.75278 13.4852L8.52812 11.7885L12.2068 14.4825C12.8815 14.8585 13.3568 14.6605 13.5388 13.8619L15.9535 2.54719L15.9541 2.54652C16.1681 1.54919 15.5935 1.15919 14.9361 1.40385L0.742785 6.83785C-0.225881 7.21385 -0.211215 7.75385 0.578119 7.99852L4.20678 9.12719L12.6355 3.85319C13.0321 3.59052 13.3928 3.73585 13.0961 3.99852L6.27812 10.1205Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2733:53">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </ShareNetwork>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['currentSign'],
        computed: {
            url() {
                return window.location.href;
            },
            title() {
                return 'Гороскоп от Zarina';
            },
            description() {
                return 'Посмотрите мой гороскоп для знака ' + this.currentSign + ' от Zarina';
            },
            media() {
                return 'https://zodiac.zarina.ru/og-image.jpg';
            }
        }
    }
</script>

<style lang="scss">
    .to-share {
        display: flex;
        width: 296px;
        height: 147px;
        padding: 1px;
        box-sizing: border-box;
        position: relative;
        background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%); //yellow;
        border-radius: 24px;
        box-shadow: 0 12px 16px rgba(0, 0, 0, 0.1);

        @include up($md) {
            width: 381px;
            height: 88px;
            border-radius: 60px;
        }

        &__inner {
            width: 100%;
            height: 100%;
            padding: 24px 24px 32px 24px;
            box-sizing: border-box;
            background: conic-gradient(from 155.62deg at 50% 50%, $card-primary 0deg, $card-secondary 184.19deg, $card-primary 360deg, $card-primary 360deg);
            border-radius: 24px;
            @include up($md) {
                padding: 24px 24px 24px 46px;
                border-radius: 60px;
            }
        }

        ul {
            width: 154px;
            margin: 0 auto;
        }

        &__link {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $text-color;
            padding: 12px;
            display: block;
            @include transition;

            &:hover {
                background-color: $white-color;

                svg path {
                    @include transition;
                    fill: $text-color;
                }
            }
        }
    }
</style>