<template>
    <div class="home" id="home">

        <choose-sign id="intro"/>
        <sign-cards id="signs"/>
        <socials id="socials"/>

    </div>
</template>


<script>
    import ChooseSign from "./home/choose-sign";
    import SignCards from "./home/sign-cards";
    import Socials from "./home/socials";

    export default {
        components: {Socials, SignCards, ChooseSign}
    }
</script>
